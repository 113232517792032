

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: #015c8f;

  }



  html,
  body,
  #root,
  .App,
  .fullHeight {

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

  }

  p {
    font-family: garamond-premier-pro, serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
  }

  h3 {
    font-family: garamond-premier-pro, serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    margin: 1% 0;
  }

  h2 {
    font-family: garamond-premier-pro, serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    margin: 1% 0;
  }

  h1 {
    font-family: garamond-premier-pro, serif;
    font-weight: 700;
    font-style: normal;
    color: rgb(0, 42, 96);
    margin: 3% 0;
  }

  a {
    text-decoration: none;
  }
