

@media screen and (max-width: 600px) {

    .phone-header-container {
        width: 100%;
        height: 8%;
        display: flex;
        box-shadow: 0 0px 15px black;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(0, 42, 96);
        position: fixed;
        top: 0;
        z-index: 1000;
    }

    .donation-button {
        height: 80%;
        width: 110%;
        background-color: white;
        border: 2px solid rgb(0, 42, 96);
        border-radius: 5%/10%;
        cursor: pointer;
    }

    .donation-button-text {
        margin: 8% 0;
    }

    .logo-container {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .logo {
        width: 40%;
        margin-left: 3%;
    }

    .menu {
        margin-right: 2%;
        
    }

    .wide-menu {
        width: 40%;
        margin: 0 3% 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
    }
    
    .menu-text {
        color: white;
    }

    
}

@media screen and (min-width: 601px) and (max-width: 924px) {

    .phone-header-container {
        width: 100%;
        height: 8%;
        display: flex;
        align-items: center;
        box-shadow: 0 0px 15px black;
        flex-direction: row;
        justify-content: space-between;
        background-color: rgb(0, 42, 96);
        position: fixed;
        top: 0;
        z-index: 1000;
    }

    .donation-button {
        height: 80%;
        width: 110%;
        background-color: white;
        border: 2px solid rgb(0, 42, 96);
        border-radius: 5%/10%;
        cursor: pointer;
    }

    .donation-button-text {
        margin: 8% 0;
    }

    .logo-container {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }

    .logo {
        width: 35%;
        margin-left: 2%;
    }

    .menu {
        margin-right: 2%;
        
    }

    .wide-menu {
        width: 40%;
        margin: 0 3% 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
    }
    
    .menu-text {
        color: white;
        font-size: 2.5rem;
    }

}

@media screen and (min-width: 925px) {
    .phone-header-container {
        width: 100%;
        height: 12%;
        display: flex;
        align-items: center;
        box-shadow: 0 0px 15px black;
        flex-direction: row;
        justify-content: space-between;
        background-color: rgb(0, 42, 96);
        position: fixed;
        top: 0;
        z-index: 100;
    }
    
    
    
    #donation-button {
        height: 50px;
        width: 100px;
        margin: 0;
        background-color: white;
        border: 2px solid rgb(0, 42, 96);
        border-radius: 5%/10%;
        cursor: pointer;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        color: rgb(0, 42, 96);
        padding: 5% 5%;
        font-size: 1.4rem;
    }

    #donation-button:hover {
        background-color: #015c8f;
        color: white;
    }
    
    .donation-button-text {
        margin: 8% 0;
        font-size: 1.4rem;
    }

    .donation-button-text:hover {
        color: white;
    }
    
    .logo-container {
        height: 100%;
        width: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }
    
    
    
    .logo {
        width: 40%;
        margin-left: 3%;

    }
    
    .menu {
        margin-right: 2%;
        
    }
    
    .wide-menu {
        width: 80%;
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
    }

    .menu-links-text {
        font-size: 1.6rem;
    }
    
    .menu-text {
        color: white;
        
    }

    .wide-menu-links {
        width: 100%;
        height: 100%;
        text-decoration: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;


    }
    }
