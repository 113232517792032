@media screen and (max-width: 600px) {
    .footer-container {

        background-color: rgb(0, 42, 96);
        padding-top: 5%;
    }

    .footer-logo {
        width: 30%;
        margin: 0 35% 2%;

    }

    .donation-container {
        text-align: center;
        margin-bottom: 2%;
    }

    .donate-title {
        font-size: 1.2rem;
    }

    .footer-donate-button {
        width: 30%;
        border: none;
        border-radius: 5%/10%;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.4rem;
        background-color: white;
        color: rgb(0, 42, 96);
        padding: 2.5%;
        cursor: pointer;
    }

    .social-media-container {
        margin-top: 3%;
        width: 100%;
        background-color: white;
        padding: 1% 0 2%;
        
    }

    .social-media-text {
        color: rgb(0, 42, 96);
        font-size: 1.4rem;
    }

    .social-links-container {
        width: 65%;
        margin: 0 17.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .social-links {
        width: 15%;
    }

    .tag {
        width: 90%;
        margin: 3% 5% 0;
        text-align: center;

    }

    .photo-link {
        color: rgb(92, 172, 218)
    }

    .paid-for-container {
        text-align: center;
    }
}

@media screen and (min-width: 601px) and (max-width: 924px) {
    .footer-container {

        background-color: rgb(0, 42, 96);
        padding-top: 5%;
    }

    .footer-logo {
        width: 30%;
        margin: 0 35% 2%;

    }

    .donation-container {
        text-align: center;
        margin-bottom: 2%;
    }

    .footer-donate-button {
        width: 30%;
        border: none;
        border-radius: 5%/10%;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.4rem;
        background-color: white;
        color: rgb(0, 42, 96);
        padding: 2.5%;
        cursor: pointer;
    }

    .social-media-container {
        margin-top: 3%;
        width: 100%;
        background-color: white;
        padding: 1% 0 2%;
        
    }

    .social-media-text {
        color: rgb(0, 42, 96);
        font-size: 2.2rem;
    }

    .social-links-container {
        width: 35%;
        margin: 0 32.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .social-links {
        width: 17%;
    }

    .tag {
        width: 90%;
        margin: 3% 5% 0;
        text-align: center;

    }

    .photo-link {
        color: rgb(92, 172, 218)
    }

    .paid-for-container {
        text-align: center;
    }
}

@media screen and (min-width: 925px) {
    .footer-container {
        height: auto;
        background-color: rgb(0, 42, 96);
        padding-top: 5%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footer-logo {
        width: 15%;
        margin: 0 17.5% 2%;

    }

    .donation-container {
        text-align: center;
       
        width: 50%;

    }

    .footer-donate-button {
        width: 100px;
        height: 50px;
        border: none;
        border-radius: 5%/10%;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.4rem;
        background-color: white;
        color: rgb(0, 42, 96);
        
        cursor: pointer;
    }

    .footer-donate-button:hover {
        background-color: #015c8f;
        color: white;
    }

    .social-media-container {
        margin-top: 3%;
        width: 100%;
        background-color: white;
        padding: 1% 0 2%;
        
    }

    .social-media-text {
        color: rgb(0, 42, 96);
        font-size: 1.6rem;
    }

    .social-links-container {
        width: 25%;
        margin: 0 37.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .social-links {
        width: 17%;
    }

    .tag {
        text-align: center;
        margin: 1% 5% 0;
    }

    .photo-link {
        color: rgb(92, 172, 218)
    }

    .paid-for-container {
        text-align: center;
        width: 100%;
    }
}