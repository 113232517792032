@media screen and (max-width: 600px) {
    .contact-container {
        width: 90%;
        margin:20% 5% 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    #circle-container {
        width: 75%;
        height: 75%;
        margin: 0 12.5%;
        background-color: white;
        border-radius: 50%;
    }

    .contact-img-container {

        margin-bottom: 5%;
    }
    
    .contact-img {
        width: 70%;
        height: 70%;
        margin: 15% 15%;
    }

    #contact-form-title {
        color: white;
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
    }

    #contact-form-subtitle {
        font-size: 1rem;
    }

    .contact-form-input {
        width: 97%;
        border-radius: 5px;
    }

    .contact-form-text {
        width: 97%;
        height: 100px;
        border-radius: 5px;
    }

    #contact-form-submit {
        width: 100px;
        height: 50px;
        margin: 0;
        background-color: white;
        border: none;
        border-radius: 4%/8%;
        cursor: pointer;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        color: rgb(0, 42, 96);
        
        font-size: 1.6rem;
    }
    
    .email-link {
        color: rgb(92, 172, 218);
    }
}

@media screen and (min-width: 601px) {
    .contact-container {
        width: 90%;
        margin: 20% 5% 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-img-container {
        width: 300px;
        height: 300px;
        margin-bottom: 5%;
    }
    
    .contact-img {
        width: 70%;
        margin: 15% 15%;
    }
    
    .email-link {
        color: rgb(92, 172, 218);
    }
}

@media screen and (min-width: 925px) {
.contact-container {
    width: 80%;
    margin: 10% 10% 0;
    text-align: center;
}

.contact-img-container {
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

#circle-container {
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-img {
    width: 70%;
    margin: auto;
}

.contact-form {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#contact-form-title {
    color: white;
    text-align: center;
    font-size: 2.5rem;
    width: 100%;
    margin: 0;
}

.contact-form-input {
    width: 100%;
}

#contact-form-sub {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#contact-form-input-sub-1 {
    width: 100%;
    margin: 0;
}

#contact-form-input-sub-2 {
    width: 100%;
}

.contact-form-text {
    width: 100%;
    height: 100px;
}

#contact-form-submit {
    width: 100px;
    height: 50px;
    margin: 0;
    background-color: white;
    border: none;
    border-radius: 4%/8%;
    cursor: pointer;
    font-family: garamond-premier-pro, serif;
    font-weight: 700;
    font-style: normal;
    color: rgb(0, 42, 96);
    
    font-size: 1.6rem;
}

#contact-form-submit:hover {
    background-color: #015c8f;
    border: 2px solid white;
    color: white;
}

.email-link {
    color: rgb(92, 172, 218);
}
}