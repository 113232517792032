.menu-container {
    padding: 22% 0 5%;
    width: 100%;
    height: 20%;
    position: fixed;
    background-color: #015c8f;
    z-index: 900;
}

.menu-links {
    text-decoration: none;
    text-align: center;
    font-size: 1.3rem;
}

