


@media screen and (max-width: 600px) {

    .home-container {
        margin-top: 12%;
        height: 1250px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    

    .home-img-phone {
        width: 60%;
        margin: 0 20%;
    }

    .home-img-container {
        background: rgb(1,92,143);
        background: linear-gradient(0deg, rgba(1,92,143,1) 65%, rgba(255,255,255,1) 95%);
        height: 800px;
        width: 100%;
    }

    .home-img-1 {
        width: 100%;
    }

    .home-form {
        background: rgb(1,92,143);
        background: rgba(1,92,143,1);
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 55%;

    }

    .home-form-container {
        width: 90%;
        margin: 0 5%;
    }

    .home-form-input {
        width: 100%;
        height: 25px;
        border-radius: 5px;
        border: none;
        padding: 1%;
        font-size: 1.1rem;
    }

    .home-form-sub {
        display: flex;
        flex-direction: row;
        
    }

    .home-form-input-sub {
        width: 92%;
        height: 25px;
        border-radius: 5px;
        margin: 0;
        border: none;
        font-size: 1.1rem;
        padding: 2%;
    }

    .home-form-input-sub-2 {
        width: 100%;
        height: 25px;
        border-radius: 5px;
        margin: 0;
        border: none;
        font-size: 1.1rem;
        padding: 2%;
    }

    .home-form-text-input {
        height: 100px;
        width: 100%;
        border-radius: 5px;
        border: none;
        padding: 1%;
        font-size: 1.1rem;
    }

    #home-form-submit {
        width: 25%;
        height: 50px;
        margin: 0;
        background-color: white;
        border: none;
        border-radius: 4%/8%;
        cursor: pointer;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        color: rgb(0, 42, 96);
        
        font-size: 1.6rem;
    }

    

    .divider {
        max-width: 100%;
        
        
        position: relative;
        z-index: 10;
    
    }

    .divider-img {
        width: 100%;
    }

    .quote-signature {
        margin-top: 15%;
        margin-left: 25%;
        font-size: 3rem;
        position: absolute;
        z-index: 15;
        color: rgb(0, 42, 96);
    }

    .quote-text {
        width: 100%;
       
        position: absolute;
        font-size: 2.2rem;
        text-align: center;
        font-weight: 700;
        font-style: italic;
        z-index: 15;
        color: white;
        position: absolute;
        top: -25%;
        left: 0;
        filter: drop-shadow(.05rem .05rem .1rem black)
    }

    .quote-signature {
        font-size: 1.6rem;
        margin-top: 30%;
        margin-left: 45%;
        text-align: center;
    }

    .quote-img {
        width: 90%;
        margin: 0 5%;
        position: relative;
        z-index: 5;
        opacity: 0.9;
        filter:brightness(1.4)
    }

    .circle {
        width: 50%;
        margin: 0 25%;
    }

    .endorsement-container {
        width: 90%;
        margin: 3% 5%;
        background-color: white;
        text-align: center;
        padding: 1% 0;
    }

    .endorsement-title {
        margin: 0;
        font-size: 2rem;
    }

    .endorsement-text {
        color: rgb(0, 42, 96);
        font-size: 1rem;
        margin-bottom: 4%;
    }

    .endorsement-img-container {
        width: 75%;
        margin: 0 12.5% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .endorsement-img {
        object-fit: contain;
        width: 40%;
    }

}

@media screen and (min-width: 601px) and (max-width: 924px) {

    .home-container {
        margin-top: 12%;
        height: 1750px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-img-phone {
        width: 50%;
        margin: 0 25%;
    }

    .home-img-container {
        background: rgb(1,92,143);
        background: linear-gradient(0deg, rgba(1,92,143,1) 45%, rgba(255,255,255,1) 95%);
        height: 1200px;
        width: 100%;
    }

    .home-form {
        background: rgb(1,92,143);
        background: rgba(1,92,143,1);
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 75%;

    }

    

    .divider {
        max-width: 100%;
        
        
        position: relative;
        z-index: 10;
    
    }

    .divider-img {
        width: 100%;
    }



    .quote-text {
        width: 100%;
       
        position: absolute;
        font-size: 5rem;
        text-align: center;
        font-weight: 700;
        font-style: italic;
        z-index: 15;
        color: white;
        position: absolute;
        top: -25%;
        left: 0;
        filter: drop-shadow(.05rem .05rem .1rem black)
    }

    .quote-signature {
        margin-top: 25%;
        margin-left: 55%;
        font-size: 2.5rem;
        position: absolute;
        z-index: 15;
        color: rgb(0, 42, 96);
        text-align: center;
    }

    .quote-img {
        width: 90%;
        margin: 0 5%;
        position: relative;
        z-index: 5;
        opacity: 0.9;
        filter:brightness(1.4)
    }

    .home-form-container {
        width: 90%;
        margin: 0 5%;
    }

    .home-form-input {
        width: 100%;
        height: 25px;
        border: none;
        border-radius: 5px;
        padding: 1%;
        font-size: 1.1rem;
    }

    .home-form-sub {
        display: flex;
        flex-direction: row;
        
    }

    .home-form-input-sub {
        width: 92%;
        height: 25px;
        border-radius: 5px;
        margin: 0;
        border: none;
        font-size: 1.1rem;
        padding: 2%;
    }

    .home-form-input-sub-2 {
        width: 100%;
        height: 25px;
        margin: 0;
        border: none;
        border-radius: 5px;
        font-size: 1.1rem;
        padding: 2%;
    }

    .home-form-text-input {
        height: 100px;
        width: 100%;
        border-radius: 5px;
        border: none;
        border-radius: 5px;
        padding: 1%;
        font-size: 1.1rem;
    }

    #home-form-submit {
        width: 25%;
        height: 50px;
        margin: 0;
        background-color: white;
        border: none;
        border-radius: 4%/8%;
        cursor: pointer;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        color: rgb(0, 42, 96);
        
        font-size: 1.6rem;
    }


    .endorsement-container {
        width: 90%;
        margin: 3% 0;
        background-color: white;
        text-align: center;
        padding: 1%
    }

    .endorsement-title {
        margin: 0;
        font-size: 3rem;
    }

    .endorsement-text {
        color: rgb(0, 42, 96);
        font-size: 1.5rem;
        margin-bottom: 4%;
    }

    .endorsement-img-container {
        width: 85%;
        margin: 0 7.5% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .endorsement-img {
        width: 20%;
    }
}

@media screen and (min-width: 925px) {
    .home-container {
        margin-top: 0;
        min-height: 100%;
    
    }
    
    .home-img-container {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: rgb(1,92,143);
        background: linear-gradient(90deg, rgba(1,92,143,1) 40%, rgba(255,255,255,1) 85%);
        margin-top: 5%;
        
    }

    .home-img {
        width: 18%;
        margin: 2% 2% 0 0 ;
        
    }

    .home-form {
        width: 50%;
        margin-left: 10%;
        margin-right: 5%;
        height: 100%;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
    }

    .home-form-input {
        width: 100%;
        border-radius: 5px;
        border: none;
        padding: 1%;
    }

    .home-form-sub {
        display: flex;
        flex-direction: row;
        
    }

    .home-form-input-sub {
        width: 92%;
        margin: 0;
        border: none;
        border-radius: 5px;
        padding: 2%;
    }

    .home-form-input-sub-2 {
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 5px;
        padding: 2%;
    }

    .home-form-text-input {
        height: 100px;
        width: 100%;
        border: none;
        padding: 1%;
        border-radius: 5px;
    }

    #home-form-submit {
        width: 100px;
        height: 50px;
        margin: 0;
        background-color: white;
        border: none;
        border-radius: 4%/8%;
        cursor: pointer;
        font-family: garamond-premier-pro, serif;
        font-weight: 700;
        font-style: normal;
        color: rgb(0, 42, 96);
        
        font-size: 1.6rem;
    }

    #home-form-submit:hover {
        background-color: #015c8f;
        border: 2px solid white;
        color: white;
    }

    .home-form-submit-text {
        font-size: 1.4rem;
        margin: 0;
    }
    
    .divider {
        width: 100%;
        position: relative;
        z-index: 10;

    }

    .divider-img {
        width: 100%;
        
        background-color: white;
        padding: 5px 0;
    }
    
    .quote-text {
       
        font-size: 4.5rem;

        font-weight: 700;
        font-style: italic;
        color: white;
        margin: 0;
        filter: drop-shadow(.05rem .05rem .1rem black)
    }
    
    .quote-signature {
        margin-top: 15%;
        margin-left: 65%;
        font-size: 3rem;
        position: absolute;
        z-index: 15;
        color: rgb(0, 42, 96);
        text-align: center;
    }
    
    
    
    .quote-img {
        width: 90%;
        margin: 0 5%;
        position: relative;
        z-index: 5;
        opacity: 0.9;
        filter:brightness(1.4)
    }

    .endorsement-container {
        width: 75%;
        margin: 3% 12.5%;
        background-color: white;
        text-align: center;
        padding: 1%
    }

    .endorsement-title {
        margin: 0;
        font-size: 2.5rem;
    }

    .endorsement-text {
        color: rgb(0, 42, 96);
        font-size: 1.5rem;
        margin-bottom: 4%;
    }

    .endorsement-img-container {
        width: 75%;
        margin: 0 12.5% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .endorsement-img {
        width: 20%;
        object-fit: contain;
    }
    }