.btn-container {
    margin-top: 1%;
    
}

.btn {
    background-color: white;
    width: 20%;
    height: 15%;
    margin: 0 40%;
    border: 2px solid rgb(0, 42, 96);
    border-radius: 2.5%/10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    
}


@media screen and (max-width: 925px) {
    .btn {
        width: 50%;
        height: 10%;
        margin: 2% 25%;
        border: 2px solid rgb(0, 42, 96);
        border-radius: 9%/30%;
    }


}