.about-container {
    position: relative;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.about-title {
    margin: 0;
    color: white;
    margin-bottom: 3%;
    text-align: center;
    font-size: 2.5rem;
    width: 100%;
}

.about-text-container {
    width: 100%;
}

.about-top-img {
    width: 100%;
    object-fit: cover;
    
    
    margin-bottom: 1%;
}

.about-text {
    width: 98%;
    margin: 0 1% 3% 1%;
    text-align: justify;
}

.about-bottom-img {
    width: 25%;
    margin: 0 37.5%;

}

.about-bottom-img-container {
    width: 100%;
    background-color: white;

}

@media screen and (max-width: 600px) {

    .about-container {
        margin-top: 20%;
        margin-bottom: 20%;
    }


    .about-text-container {
        width: 100%;
        margin: 0;
    }

    .about-top-img {
        width: 100%;
        height: auto;
    }

    .about-text {
        width: 90%;
        margin: 5%;
    }

    .about-bottom-img {
        width: 50%;
        margin: 0 25%;
    }
}

@media screen and (min-width: 601px) and (max-width: 924px) {
    .about-container {
        margin-top: 15%;
        margin-bottom: 20%;
    }

    .about-title {
        font-size: 1.8rem;
    }

    .about-text-container {
        width: 90%;
        margin: 0 5%;
    }

    .about-top-img {
        width: 100%;
        height: auto;
    }

    .about-bottom-img {
        width: 35%;
        margin: 0 32.5%;
    }
}